@font-face {
  font-family: 'Stratum';
  src: url('/fonts/Stratum1-Black.otf');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

/* Hide scrollbar for Chrome, Safari, and Opera */
body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar in Firefox, IE, and Edge */
body {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}
